exports.components = {
  "component---src-pages-bipolar-404-index-jsx": () => import("./../../../src/pages/bipolar/404/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-404-index-jsx" */),
  "component---src-pages-bipolar-500-index-jsx": () => import("./../../../src/pages/bipolar/500/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-500-index-jsx" */),
  "component---src-pages-bipolar-about-bipolar-depression-index-jsx": () => import("./../../../src/pages/bipolar/about-bipolar-depression/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-about-bipolar-depression-index-jsx" */),
  "component---src-pages-bipolar-ask-about-caplyta-index-jsx": () => import("./../../../src/pages/bipolar/ask-about-caplyta/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-ask-about-caplyta-index-jsx" */),
  "component---src-pages-bipolar-cookie-policy-index-jsx": () => import("./../../../src/pages/bipolar/cookie-policy/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-cookie-policy-index-jsx" */),
  "component---src-pages-bipolar-cost-coverage-index-jsx": () => import("./../../../src/pages/bipolar/cost-coverage/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-cost-coverage-index-jsx" */),
  "component---src-pages-bipolar-patient-videos-index-jsx": () => import("./../../../src/pages/bipolar/patient-videos/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-patient-videos-index-jsx" */),
  "component---src-pages-bipolar-references-index-jsx": () => import("./../../../src/pages/bipolar/references/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-references-index-jsx" */),
  "component---src-pages-bipolar-resources-index-jsx": () => import("./../../../src/pages/bipolar/resources/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-resources-index-jsx" */),
  "component---src-pages-bipolar-results-index-jsx": () => import("./../../../src/pages/bipolar/results/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-results-index-jsx" */),
  "component---src-pages-bipolar-savings-offer-index-jsx": () => import("./../../../src/pages/bipolar/savings-offer/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-savings-offer-index-jsx" */),
  "component---src-pages-bipolar-side-effects-index-jsx": () => import("./../../../src/pages/bipolar/side-effects/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-side-effects-index-jsx" */),
  "component---src-pages-bipolar-sitemap-index-jsx": () => import("./../../../src/pages/bipolar/sitemap/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-sitemap-index-jsx" */),
  "component---src-pages-bipolar-taking-caplyta-index-jsx": () => import("./../../../src/pages/bipolar/taking-caplyta/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-taking-caplyta-index-jsx" */),
  "component---src-pages-bipolar-tools-index-jsx": () => import("./../../../src/pages/bipolar/tools/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-tools-index-jsx" */),
  "component---src-pages-bipolar-transcript-commercial-index-jsx": () => import("./../../../src/pages/bipolar/transcript/commercial/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-transcript-commercial-index-jsx" */),
  "component---src-pages-bipolar-transcript-craigs-story-index-jsx": () => import("./../../../src/pages/bipolar/transcript/craigs-story/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-transcript-craigs-story-index-jsx" */),
  "component---src-pages-bipolar-transcript-lindsays-story-index-jsx": () => import("./../../../src/pages/bipolar/transcript/lindsays-story/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-transcript-lindsays-story-index-jsx" */),
  "component---src-pages-bipolar-transcript-pauls-story-index-jsx": () => import("./../../../src/pages/bipolar/transcript/pauls-story/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-transcript-pauls-story-index-jsx" */),
  "component---src-pages-bipolar-transcript-teresas-story-index-jsx": () => import("./../../../src/pages/bipolar/transcript/teresas-story/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-transcript-teresas-story-index-jsx" */),
  "component---src-pages-bipolar-transcript-vickies-story-index-jsx": () => import("./../../../src/pages/bipolar/transcript/vickies-story/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-transcript-vickies-story-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-schizophrenia-404-index-jsx": () => import("./../../../src/pages/schizophrenia/404/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-404-index-jsx" */),
  "component---src-pages-schizophrenia-500-index-jsx": () => import("./../../../src/pages/schizophrenia/500/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-500-index-jsx" */),
  "component---src-pages-schizophrenia-about-caplyta-index-jsx": () => import("./../../../src/pages/schizophrenia/about-caplyta/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-about-caplyta-index-jsx" */),
  "component---src-pages-schizophrenia-about-schizophrenia-index-jsx": () => import("./../../../src/pages/schizophrenia/about-schizophrenia/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-about-schizophrenia-index-jsx" */),
  "component---src-pages-schizophrenia-cookie-policy-index-jsx": () => import("./../../../src/pages/schizophrenia/cookie-policy/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-cookie-policy-index-jsx" */),
  "component---src-pages-schizophrenia-cost-savings-index-jsx": () => import("./../../../src/pages/schizophrenia/cost-savings/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-cost-savings-index-jsx" */),
  "component---src-pages-schizophrenia-frequently-asked-questions-index-jsx": () => import("./../../../src/pages/schizophrenia/frequently-asked-questions/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-frequently-asked-questions-index-jsx" */),
  "component---src-pages-schizophrenia-how-to-take-caplyta-index-jsx": () => import("./../../../src/pages/schizophrenia/how-to-take-caplyta/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-how-to-take-caplyta-index-jsx" */),
  "component---src-pages-schizophrenia-index-jsx": () => import("./../../../src/pages/schizophrenia/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-index-jsx" */),
  "component---src-pages-schizophrenia-patient-caregiver-support-index-jsx": () => import("./../../../src/pages/schizophrenia/patient-caregiver-support/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-patient-caregiver-support-index-jsx" */),
  "component---src-pages-schizophrenia-side-effects-index-jsx": () => import("./../../../src/pages/schizophrenia/side-effects/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-side-effects-index-jsx" */),
  "component---src-pages-schizophrenia-sitemap-index-jsx": () => import("./../../../src/pages/schizophrenia/sitemap/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-sitemap-index-jsx" */),
  "component---src-pages-schizophrenia-talking-to-a-healthcare-provider-index-jsx": () => import("./../../../src/pages/schizophrenia/talking-to-a-healthcare-provider/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-talking-to-a-healthcare-provider-index-jsx" */),
  "component---src-pages-schizophrenia-transcript-index-jsx": () => import("./../../../src/pages/schizophrenia/transcript/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-transcript-index-jsx" */)
}

